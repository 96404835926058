import NavMenu from "../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../components/public/others/NavbarCM";
import Footer from "../../../../components/public/others/Footer";
import { TipoPortal } from "../../../../services/variables";
import MenuSalaEmpreendedor from "../../../../components/public/transparency/salaEmpreendedor/MenuEmpreendedor";
import SubTitle from "./../../../../components/public/others/title/SubTitle";
import "./homeSala.css";

export default function homeSalaEmpreendedor(props) {
  const route = props.route;

  return (
    <>
      {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
      {TipoPortal == 3 ? (
        <>
          <NavMenu />
        </>
      ) : (
        <>
          <NavMenuCM />
        </>
      )}
      <div className="section-title rotes_pages">
        <h6>Início {route}</h6>
      </div>
      <div className="section-title-portarias">
        <h4>Sala Empreendedor</h4>
      </div>
      <MenuSalaEmpreendedor filter={"Início"} />
      <SubTitle
        subTitle={"Conheça a Sala do Empreendedor Digital"}
        border={true}
      />
      <div className="section-title">
        <p>
          A Sala do Empreendedor Digital foi criada com o objetivo de facilitar
          o acesso aos serviços voltados aos empreendedores ou futuros
          empreendedores. Preparamos esse ambiente digital onde você poderá
          fazer facilmente a abertura da sua empresa, alteração cadastral,
          solicitação de alvará, emissão de nota fiscal, baixa de empresa e
          outros serviços em um só lugar e com a facilidade do mundo digital.
        </p>
      </div>
      <div className="buttons-enpreendedor">
        <h2>Acesse nossos serviços, clique nos botões abaixo</h2>
      </div>
      <div className="buttons-enpreendedor">
        <a
          href="https://mei.receita.economia.gov.br/inscricao/login"
          target="_balnk"
        >
          Quero me formalizar
        </a>
        <a
          href="https://mei.receita.economia.gov.br/certificado/login"
          target="_balnk"
        >
          Emissão de comprovante CCMEI
        </a>
        <a
          href="https://mei.receita.economia.gov.br/alteracao/login"
          target="_balnk"
        >
          Atualização cadastral
        </a>
        <a
          href="https://www8.receita.fazenda.gov.br/SimplesNacional/Aplicacoes/ATSPO/pgmei.app/Identificacao"
          target="_balnk"
        >
          Boleto de pagamento
        </a>
        <a
          href="https://www8.receita.fazenda.gov.br/SimplesNacional/Servicos/Grupo.aspx?grp=19"
          target="_balnk"
        >
          Parcelamento de débitos
        </a>
        <a
          href="https://www8.receita.fazenda.gov.br/SimplesNacional/Aplicacoes/ATSPO/dasnsimei.app/Identificacao"
          target="_balnk"
        >
          Declaração anual de faturamento
        </a>
        <a
          href="https://mei.receita.economia.gov.br/baixa/login"
          target="_balnk"
        >
          Baixa da empresa - MEI
        </a>
        <a
          href="https://solucoes.receita.fazenda.gov.br/servicos/cnpjreva/cnpjreva_solicitacao.asp"
          target="_balnk"
        >
          Emissão de CNPJ
        </a>
        <a
          href="https://sistemas1.sefaz.ma.gov.br/sintegra/jsp/consultaSintegra/consultaSintegraFiltro.jsf"
          target="_balnk"
        >
          Inscrição Estadual / SINTEGRA - MA
        </a>
        <a
          href="https://sistemas1.sefaz.ma.gov.br/nfae/jsp/login/login.jsf"
          target="_balnk"
        >
          NFAe - Comércio
        </a>
        <a
          href="https://www.nfse.gov.br/EmissorNacional/Login?ReturnUrl=%2fEmissorNacional"
          target="_balnk"
        >
          NFAe - MEI
        </a>
        <a href="https://www.regularize.pgfn.gov.br/login" target="_balnk">
          Regularize
        </a>
        <a href="https://ma.loja.sebrae.com.br/" target="_balnk">
          Cursos Online - SEBRAE
        </a>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScfzTsXbT1iRUEQtHFjHQWLTV8H6A-h4GtU0P1HPcm2wqRULQ/viewform?pli=1"
          target="_balnk"
        >
          Fale com um de nossos agentes
        </a>
      </div>
      <br />
      <div className="buttons-enpreendedor">
        <h2>Redes sociais da Sala do Empreendedor</h2>
      </div>
      <div className="buttons-enpreendedor">
        <a
          className="link"
          target="_blank"
          href="https://api.whatsapp.com/message/HJOBAJJL73QNK1?autoload=1&app_absent=0"
        >
          <img
            src="/icons/whatsapp.svg"
            alt="logo whatsapp"
            className="svg-social"
          />
          <p className="text-social">WhatsApp</p>
        </a>
        {/* <a
          className="link"
          target="_blank"
          href="mailto:saladoempreendedorturilandia@gmail.com"
        >
          <img
            src="/icons/envelopeDark.svg"
            alt="logo whatsapp"
            className="svg-social"
          />
          <p className="text-social">Email</p>
        </a> */}
      </div>
      <br />
      <Footer />
    </>
  );
}
